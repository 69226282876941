import { useCallback, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  ClassroomFormValues,
  IClassroom,
  ISchoolYear,
} from '../services/firebase/Firebase';
import { useFirebaseAuth } from '../services/FirebaseAuthContext';
import { isSuccess } from '../jasmine-common-lib/utils/FailableResult';
import { NotificationSnackbar } from '../pages/contents/NotificationSnackbar';

export type ClassroomDialogMode = 'CREATE' | 'EDIT' | 'DELETE';

interface ClassroomDialogProps {
  mode: ClassroomDialogMode;
  classroom?: IClassroom;
  schoolYear?: ISchoolYear;
  handleClose: () => void;
  onFinish: () => void;
  open: boolean;
}

export function ClassroomDialog(props: ClassroomDialogProps) {
  const { mode, classroom, schoolYear, handleClose, onFinish, open } = props;
  const { t } = useTranslation();
  const [snackbarMessage, setSnackbarMessage] = useState<string | undefined>();
  const { educationInstitutionUser, firebaseInstance } = useFirebaseAuth();
  const deleteClassroom = useCallback(async () => {
    try {
      setSnackbarMessage(t('Deleting the Classroom'));
      const result = await firebaseInstance.deleteClassroom(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        educationInstitutionUser!.educationInstitution.id,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        classroom!.schoolYear.id,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        classroom!.id
      );
      if (isSuccess(result)) {
        setSnackbarMessage(t('The Classroom has been successfully deleted'));
        onFinish();
      } else {
        setSnackbarMessage(t('Failed to delete the Classroom'));
      }
    } catch (e) {
      console.error(e);
      setSnackbarMessage(t('Failed to delete the Classroom'));
    }
  }, [classroom]);
  const validationSchema = yup.object({
    name: yup.string().required(t('Required')),
  });
  const defaultValues = useRef<ClassroomFormValues>({
    name: '',
  });
  const formik = useFormik({
    initialValues: classroom
      ? {
          name: classroom.name,
        }
      : defaultValues.current,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSnackbarMessage(
        mode === 'EDIT'
          ? t('Updating the Classroom')
          : t('Creating a new Classroom')
      );
      try {
        const result = classroom
          ? await firebaseInstance.updateClassroom(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              educationInstitutionUser!.educationInstitution.id,
              classroom.schoolYear.id,
              classroom.id,
              values
            )
          : await firebaseInstance.createClassroom(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              educationInstitutionUser!.educationInstitution.id,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              schoolYear!.id,
              values
            );
        if (isSuccess(result)) {
          setSnackbarMessage(
            mode === 'EDIT'
              ? t('The Classroom has been successfully updated')
              : t('A Classroom has been successfully created')
          );
          onFinish();
        } else {
          setSnackbarMessage(
            mode === 'EDIT'
              ? t('Failed to update the Classroom')
              : t('Failed to create a new Classroom')
          );
        }
      } catch (e) {
        console.error(e);
        setSnackbarMessage(
          mode === 'EDIT'
            ? t('Failed to update the Classroom')
            : t('Failed to create a new Classroom')
        );
      } finally {
        setSubmitting(false);
      }
      setSubmitting(false);
    },
  });
  if (mode === 'DELETE') {
    return (
      <>
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="sm"
        >
          <Box sx={{ margin: '20px' }}>
            {t('Do you want to delete the following Classroom?')}
            <br />
            {schoolYear?.name}&nbsp;{classroom?.name}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              variant="contained"
              sx={{ margin: '16px 8px', textTransform: 'none' }}
              onClick={(e) => {
                e.stopPropagation();
                void deleteClassroom();
              }}
            >
              {t('Delete the Classroom')}
            </Button>
            <Button
              variant="contained"
              sx={{ margin: '16px 8px', textTransform: 'none' }}
              onClick={() => {
                handleClose();
              }}
            >
              {t('Close')}
            </Button>
          </Box>
        </Dialog>
        <NotificationSnackbar message={snackbarMessage} />
      </>
    );
  }
  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        {mode === 'EDIT' ? (
          <DialogTitle>{t('Editing a Classroom')}</DialogTitle>
        ) : (
          <DialogTitle>{t('Creating a Classroom')}</DialogTitle>
        )}
        <form onSubmit={formik.handleSubmit}>
          <FormControl sx={{ display: 'block', margin: '16px 8px' }}>
            <Box component="section" sx={{ display: 'flex' }}>
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  flexDirection: 'columns',
                  alignItems: 'center',
                }}
              >
                <Box component="div" sx={{ margin: '8px', width: '200px' }}>
                  {t('Name of the Classroom')}
                </Box>
                <TextField
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.name)}
                  helperText={
                    formik.errors.name ? <>{formik.errors.name}</> : null
                  }
                ></TextField>
              </Box>
            </Box>
          </FormControl>
          <Divider sx={{ margin: '8px 8px' }} />
          {mode === 'EDIT' ? (
            <Button
              variant="contained"
              type="submit"
              sx={{ margin: '16px 8px', textTransform: 'none' }}
              disabled={!formik.dirty || !formik.isValid}
            >
              {t('Save the changes')}
            </Button>
          ) : (
            <Button
              variant="contained"
              type="submit"
              sx={{ margin: '16px 8px', textTransform: 'none' }}
              disabled={!formik.dirty || !formik.isValid}
            >
              {t('Create a Classroom')}
            </Button>
          )}
          <Button
            variant="contained"
            sx={{ margin: '16px 8px', textTransform: 'none' }}
            onClick={() => {
              handleClose();
            }}
          >
            {t('Close')}
          </Button>
        </form>
      </Dialog>
      <NotificationSnackbar message={snackbarMessage} />
    </>
  );
}
