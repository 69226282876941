import { useEffect, useState } from 'react';
import { signInWithEmailAndPassword, UserCredential } from 'firebase/auth';
import { getAuth } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { useFirebaseAuth, SignInState } from '../services/FirebaseAuthContext';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';

interface SignInDialogProps {
  handleClose: () => void;
  open: boolean;
}

export function SignInDialog({ open }: SignInDialogProps) {
  const { firebaseInstance, lastSignInError, signInState } = useFirebaseAuth();
  const [signInButtonState, setSignInButtonState] = useState(true);

  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    // Enable the sign in buttons when signInState changes.
    if (
      signInState === SignInState.Unknown ||
      signInState === SignInState.SignedOut
    ) {
      setSignInButtonState(true);
    }
  }, [signInState]);

  const onLogin = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSignInButtonState(false);
    signInWithEmailAndPassword(getAuth(), email, password)
      .then((userCredential: UserCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log(user);
      })
      .catch((error: unknown) => {
        const errorMessage =
          error instanceof Error ? error.message : 'unknown reason';
        console.log(`Failed to sign in: ${errorMessage}`);
        setSignInButtonState(true);
      });
  };

  const onSignInWithGoogle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSignInButtonState(false);
    void firebaseInstance.signInWithGoogleWithRedirect(true);
  };

  const onSignInWithMicrosoft = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSignInButtonState(false);
    void firebaseInstance.signInWithMicrosoftWithRedirect(true);
  };

  const onSignInWithApple = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSignInButtonState(false);
    void firebaseInstance.signInWithAppleWithRedirect();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="sm">
      <DialogTitle>{t('Sign in')}</DialogTitle>
      <DialogContent>
        <main>
          {lastSignInError && <Alert severity="error">{lastSignInError}</Alert>}
          <section>
            <div>
              <div id="loginFormBody">
                <form>
                  <div className="fieldHorizontal">
                    <div className="fieldLabel">
                      <label htmlFor="email-address">
                        {t('Email address')}
                      </label>
                    </div>
                    <div className="fieldBody">
                      <TextField
                        id="email-address"
                        className="textInput"
                        name="email"
                        type="email"
                        required
                        placeholder={t('Email address')}
                        size="small"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="fieldHorizontal">
                    <div className="fieldLabel">
                      <label htmlFor="password">{t('Password')}</label>
                    </div>
                    <div className="fieldBody">
                      <TextField
                        id="password"
                        className="textInput"
                        name="password"
                        type="password"
                        required
                        placeholder={t('Password')}
                        size="small"
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div id="signinButtons">
                <LoadingButton
                  id="signinButton"
                  variant="contained"
                  onClick={onLogin}
                  loading={!signInButtonState}
                >
                  {t('LoginButtonText')}
                </LoadingButton>
                <hr />
                <Button
                  id="signinWithGoogleButton"
                  variant="contained"
                  onClick={onSignInWithGoogle}
                  disabled={!signInButtonState}
                >
                  {t('Sign in with Google')}
                </Button>
                <Button
                  id="signinWithMicrosoftButton"
                  variant="contained"
                  onClick={onSignInWithMicrosoft}
                  disabled={!signInButtonState}
                >
                  {t('Sign in with Microsoft')}
                </Button>
                <Button
                  id="signinWithAppleButton"
                  variant="contained"
                  onClick={onSignInWithApple}
                  disabled={!signInButtonState}
                >
                  {t('Sign in with Apple')}
                </Button>
              </div>
            </div>
          </section>
        </main>
      </DialogContent>
    </Dialog>
  );
}
