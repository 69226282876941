import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFirebaseAuth } from '../../services/FirebaseAuthContext';
import { useClassrooms, useSchoolYears } from '../../services/FirestoreHooks';
import { Loader } from './Loader';
import { ClassroomTable } from './ClassroomTable';

interface ClassroomManagementState {
  schoolYearId: string;
}

export function ClassroomManagement() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { educationInstitutionUser } = useFirebaseAuth();
  const { schoolYears, isSchoolYearsLoading } = useSchoolYears(
    educationInstitutionUser?.educationInstitution.id
  );
  const schoolYearId: string | undefined =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (location.state as ClassroomManagementState)?.schoolYearId;
  const selectedSchoolYear = schoolYears.find((schoolYear) => {
    return schoolYear.id === schoolYearId;
  });
  const { classrooms, isClassroomsLoading, refresh } = useClassrooms(
    educationInstitutionUser?.educationInstitution.id,
    schoolYearId
  );
  const schoolYearSelectorOptions = [
    { name: '----------', id: '', isCurrentSchoolYear: false },
    ...schoolYears.map((schoolYear) => {
      return {
        name: schoolYear.name,
        id: schoolYear.id,
        isCurrentSchoolYear: schoolYear.isCurrentSchoolYear,
      };
    }),
  ];
  if (isSchoolYearsLoading || isClassroomsLoading) {
    return <Loader />;
  }
  return (
    <>
      <Typography variant="h5">{t('Manage Classrooms')}</Typography>
      <Divider sx={{ margin: '16px 4px' }} />
      {schoolYears.length > 0 ? (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'baseline',
              }}
            >
              <Box sx={{ margin: '8px' }}>{t('School Year')}</Box>
              <Select
                sx={{
                  marginTop: '20px',
                  width: '400px',
                }}
                name="targetSchoolYear"
                labelId="targetSchoolYear"
                id="targetSchoolYearInput"
                value={schoolYearId || ''}
                displayEmpty
                onChange={(event) => {
                  navigate('/classrooms', {
                    state: { schoolYearId: event.target.value },
                  });
                }}
              >
                {schoolYearSelectorOptions.map((option) => {
                  return (
                    <MenuItem
                      value={option.id}
                      selected={option.id === schoolYearId}
                      key={option.id}
                    >
                      {option.name + (option.isCurrentSchoolYear ? '★' : '')}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
            <ClassroomTable
              refresh={refresh}
              classrooms={classrooms}
              targetSchoolYear={selectedSchoolYear}
            />
          </Box>
        </>
      ) : (
        <Box sx={{ marginTop: '20px' }}>
          {t(
            'There is no school year. If you create a school year, you can manage classrooms for each school year separately.'
          )}
        </Box>
      )}
    </>
  );
}
