import { useEffect, useState } from 'react';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';

interface NotificationSnackbarProps {
  message: string | undefined;
}

export function NotificationSnackbar({ message }: NotificationSnackbarProps) {
  const [snackbarState, setSnackbarState] = useState(false);
  const closeSnackBar = (
    _event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarState(false);
  };
  useEffect(() => {
    if (message) {
      setSnackbarState(true);
    }
  }, [message]);
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={snackbarState}
      autoHideDuration={6000}
      onClose={closeSnackBar}
      message={message}
    />
  );
}
