import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useFirebaseAuth } from '../../services/FirebaseAuthContext';
import { isSuccess } from '../../jasmine-common-lib/utils/FailableResult';
import {
  IEducationInstitution,
  IProjectVisibility,
} from '../../services/firebase/Firebase';
import { MessageResourceLanguage } from '../../jasmine-common-lib/Types';
import { PermissionWarning } from './PermissionWarning';
import { NotificationSnackbar } from './NotificationSnackbar';

const DEFAULT_EDUCATION_INSTITUTION: IEducationInstitution = {
  id: '',
  name: '',
  defaultProjectVisibility: 'hidden',
  gameTutorialVisible: false,
  questionsFeatureAvailable: false,
  supportedLocale: 'ja_kanji',
  currentSchoolYearId: null,
  docsMenu: '',
  classrooms: [],
  createdAt: new Date(),
  updatedAt: new Date(),
};

export function EducationInstitutionSettings() {
  const [snackbarMessage, setSnackbarMessage] = useState<string | undefined>();
  const { t } = useTranslation();
  const validationSchema = yup.object({
    defaultProjectVisibility: yup
      .mixed<IProjectVisibility>()
      .oneOf(Object.values(['public', 'hidden', 'education_institution']))
      .required(t('Required')),
    supportedLocale: yup
      .mixed<MessageResourceLanguage>()
      .oneOf(Object.values(['ja_kanji', 'ja_kanji_elementary', 'en']))
      .required(t('Required')),
    gameTutorialVisible: yup.boolean(),
    questionsFeatureAvailable: yup.boolean(),
  });

  const { educationInstitutionUser, firebaseInstance } = useFirebaseAuth();

  const formik = useFormik({
    initialValues:
      educationInstitutionUser?.educationInstitution ??
      DEFAULT_EDUCATION_INSTITUTION,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSnackbarMessage(t('Updating Education Institution'));
      try {
        const result =
          await firebaseInstance.updateEducationInstitution(values);
        if (isSuccess(result)) {
          setSnackbarMessage(
            t('Education Institution has been successfully updated')
          );
        } else {
          setSnackbarMessage(t('Failed to update Education Institution'));
        }
      } catch (e) {
        console.error(e);
        setSnackbarMessage(t('Failed to update Education Institution'));
      } finally {
        setSubmitting(false);
      }
      setSubmitting(false);
      location.reload();
    },
  });

  if (educationInstitutionUser) {
    if (educationInstitutionUser.userType !== 'teacher') {
      return <PermissionWarning />;
    }
    return (
      <Box>
        <Typography variant="h5">
          {t('Education Institution Settings')}
        </Typography>
        <Divider />
        <Box component="section" sx={{ display: 'block', margin: '16px 0px' }}>
          {t('Education Institution Name')}:{' '}
          {educationInstitutionUser.educationInstitution.name}
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <FormControl sx={{ display: 'block', margin: '16px 0px' }}>
            <Box component="section" sx={{ display: 'flex' }}>
              <InputLabel shrink id="defaultProjectVisibility">
                {t('Default Project Visibility')}
              </InputLabel>
              <Select
                name="defaultProjectVisibility"
                labelId="defaultProjectVisibility"
                id="defaultProjectVisibilityInput"
                value={formik.values.defaultProjectVisibility}
                label={t('Default Project Visibility')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.defaultProjectVisibility &&
                  Boolean(formik.errors.defaultProjectVisibility)
                }
                displayEmpty
              >
                <MenuItem value="" disabled>
                  {t('Please Select')}
                </MenuItem>
                <MenuItem value="public">
                  {t('project_visibility_public')}
                </MenuItem>
                <MenuItem value="hidden">
                  {t('project_visibility_hidden')}
                </MenuItem>
                <MenuItem value="education_institution">
                  {t('project_visibility_education_institution')}
                </MenuItem>
              </Select>
            </Box>
          </FormControl>
          <FormControl sx={{ display: 'block', margin: '16px 0px' }}>
            <Box component="section" sx={{ display: 'flex' }}>
              <InputLabel shrink id="supportedLocale">
                {t('Supported Locale')}
              </InputLabel>
              <Select
                name="supportedLocale"
                labelId="supportedLocale"
                id="supportedLocaleInput"
                value={formik.values.supportedLocale}
                label={t('Supported Locale')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.supportedLocale &&
                  Boolean(formik.errors.supportedLocale)
                }
                displayEmpty
              >
                <MenuItem value="" disabled>
                  {t('Please Select')}
                </MenuItem>
                <MenuItem value="ja_kanji">
                  {t('supported_locale_ja_kanji')}
                </MenuItem>
                <MenuItem value="ja_kanji_elementary">
                  {t('supported_locale_ja_kanji_elementary')}
                </MenuItem>
                <MenuItem value="en">{t('supported_locale_en')}</MenuItem>
              </Select>
            </Box>
          </FormControl>
          <Box component="section" sx={{ display: 'flex' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.gameTutorialVisible}
                  onChange={() => {
                    void formik.setFieldValue(
                      'gameTutorialVisible',
                      !formik.values.gameTutorialVisible
                    );
                  }}
                />
              }
              label={t('Show game tutorial')}
            />
          </Box>
          <Box component="section" sx={{ display: 'flex' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.questionsFeatureAvailable}
                  onChange={() => {
                    void formik.setFieldValue(
                      'questionsFeatureAvailable',
                      !formik.values.questionsFeatureAvailable
                    );
                  }}
                />
              }
              label={t('Make Question feature available')}
            />
          </Box>
          <Divider />
          <Button
            variant="contained"
            type="submit"
            sx={{ margin: '16px 0px', textTransform: 'none' }}
            disabled={!formik.dirty}
          >
            {t('Update Education Institution Settings')}
          </Button>
        </form>
        <NotificationSnackbar message={snackbarMessage} />
      </Box>
    );
  }
}
