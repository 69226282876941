import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Route, Routes } from 'react-router-dom';
import { Skeleton } from './pages/Skeleton';

export function App() {
  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<Skeleton />}></Route>
        <Route path="/schoolYears" element={<Skeleton />}></Route>
        <Route path="/classrooms" element={<Skeleton />}></Route>
      </Routes>
    </div>
  );
}
