import { useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useFirebaseAuth } from '../../services/FirebaseAuthContext';
import { SchoolYearTable } from './SchoolYearTable';
import { SchoolYearDialog } from '../../dialogs/SchoolYearDialog';
import {
  successResult,
  errorResultOf,
} from '../../jasmine-common-lib/utils/FailableResult';
import {
  DateValidator,
  END_DATE_ERROR_MUST_BE_FUTURE,
} from '../../utils/SchoolYearUtils';

export function SchoolYearManagement() {
  const { t } = useTranslation();
  const { educationInstitutionUser, refreshEducationInstitutionUser } =
    useFirebaseAuth();
  const [schoolYearDialogState, setSchoolYearDialogState] = useState(false);
  const handleClose = useCallback(() => {
    setSchoolYearDialogState(false);
  }, []);
  const onFinish = useCallback(() => {
    setSchoolYearDialogState(false);
    setTimeout(() => {
      void refreshEducationInstitutionUser();
    }, 1000);
  }, []);
  const dateValidator: DateValidator = useCallback((d: Date) => {
    if (d <= new Date()) {
      return errorResultOf(END_DATE_ERROR_MUST_BE_FUTURE);
    }
    return successResult();
  }, []);
  return (
    <>
      <Typography variant="h5">{t('Manage School Year')}</Typography>
      {educationInstitutionUser?.educationInstitution.currentSchoolYearId ? (
        <SchoolYearTable />
      ) : (
        <>
          <Box sx={{ marginTop: '20px' }}>
            {t(
              'There is no school year. If you create a school year, you can manage classrooms for each school year separately.'
            )}
          </Box>
          <Button
            variant="contained"
            sx={{
              width: '200px',
              marginTop: '20px',
            }}
            onClick={(e) => {
              e.stopPropagation();
              setSchoolYearDialogState(true);
            }}
          >
            {t('Create a School Year')}
          </Button>
          {/* The SchoolYearDialog below is always for creating the current
           * school year.
           */}
          <SchoolYearDialog
            mode={'CREATE'}
            handleClose={handleClose}
            onFinish={onFinish}
            open={schoolYearDialogState}
            isCreatingCurrent={
              educationInstitutionUser?.educationInstitution
                .currentSchoolYearId === null
            }
            dateValidator={dateValidator}
          />
        </>
      )}
    </>
  );
}
