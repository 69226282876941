import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DataGrid,
  gridClasses,
  GridColDef,
  GridValidRowModel,
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useFirebaseAuth } from '../../services/FirebaseAuthContext';
import { useSchoolYears } from '../../services/FirestoreHooks';
import {
  SchoolYearDialog,
  SchoolYearDialogMode,
} from '../../dialogs/SchoolYearDialog';
import { ISchoolYear } from '../../services/firebase/Firebase';
import {
  errorResultOf,
  successResult,
} from '../../jasmine-common-lib/utils/FailableResult';
import {
  DateValidator,
  END_DATE_ERROR_MUST_BE_LATER_THAN_PREVIOUS_SCHOOL_YEAR,
  useDateValidators,
} from '../../utils/SchoolYearUtils';
import { Loader } from './Loader';

type SchoolYearType =
  | 'PAST_SCHOOL_YEAR'
  | 'CURRENT_SCHOOL_YEAR'
  | 'NEXT_SCHOOL_YEAR';

export function SchoolYearTable() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [dateValidator, setDateValidator] = useState<DateValidator>(
    useCallback((_d: Date) => {
      return errorResultOf('No date validater is configured');
    }, [])
  );
  const [targetSchoolYear, setTargetSchoolYear] = useState<
    ISchoolYear | undefined
  >();
  const [schoolYearDialogState, setSchoolYearDialogState] = useState(false);
  const [schoolYearDialogMode, setSchoolYearDialogMode] =
    useState<SchoolYearDialogMode>('CREATE');
  const handleClose = useCallback(() => {
    setTargetSchoolYear(undefined);
    setSchoolYearDialogState(false);
  }, []);
  const onFinish = useCallback(() => {
    setTargetSchoolYear(undefined);
    setSchoolYearDialogState(false);
    setTimeout(() => {
      refresh();
    }, 1000);
  }, []);
  const { educationInstitutionUser } = useFirebaseAuth();
  const isJapanese =
    educationInstitutionUser?.educationInstitution.supportedLocale ===
      'ja_kanji' ||
    educationInstitutionUser?.educationInstitution.supportedLocale ===
      'ja_kanji_elementary';
  const { schoolYears, refresh, isSchoolYearsLoading } = useSchoolYears(
    educationInstitutionUser?.educationInstitution.id
  );
  const currentSchoolYear = schoolYears.find((schoolYear) => {
    return (
      schoolYear.id ===
      educationInstitutionUser?.educationInstitution.currentSchoolYearId
    );
  });
  const currentSchoolYearName = currentSchoolYear?.name;
  const dateValidatorForNextSchoolYear: DateValidator = useCallback(
    (d: Date) => {
      if (d <= schoolYears[schoolYears.length - 1].endDate) {
        return errorResultOf(
          END_DATE_ERROR_MUST_BE_LATER_THAN_PREVIOUS_SCHOOL_YEAR
        );
      }
      return successResult();
    },
    [schoolYears]
  );

  let schoolYearType: SchoolYearType = 'PAST_SCHOOL_YEAR';
  const rows: GridValidRowModel[] = [];

  const dateValidators = useDateValidators(
    schoolYears,
    //eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
    educationInstitutionUser?.educationInstitution.currentSchoolYearId as string
  );
  let hasNextYear = false;
  for (let i = 0; i < schoolYears.length; ++i) {
    const schoolYear = schoolYears[i];
    if (schoolYearType === 'CURRENT_SCHOOL_YEAR') {
      schoolYearType = 'NEXT_SCHOOL_YEAR';
      hasNextYear = true;
    }
    if (schoolYear.isCurrentSchoolYear) {
      schoolYearType = 'CURRENT_SCHOOL_YEAR';
    }
    rows.push({
      id: schoolYear.id,
      name: schoolYear.name + (schoolYear.isCurrentSchoolYear ? '★' : ''),
      classroomCount: schoolYear.numberOfClassRooms,
      endDate: schoolYear.endDate,
      schoolYear: schoolYear,
      schoolYearType: schoolYearType,
      dateValidator: dateValidators[i],
    });
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('Name of the school year'),
      width: 200,
      sortable: false,
      filterable: false,
      hideable: false,
      type: 'string',
    },
    {
      field: 'classroomCount',
      headerName: t('# of classrooms'),
      width: 150,
      sortable: false,
      filterable: false,
      hideable: false,
      type: 'number',
    },
    {
      field: 'endDate',
      headerName: t('end date'),
      width: 150,
      sortable: false,
      filterable: false,
      hideable: false,
      type: 'date',
      valueFormatter: (val: Date) =>
        isJapanese ? format(val, 'yyyy/MM/dd') : format(val, 'MM/dd/yyyy'),
    },
    {
      field: 'actions',
      headerName: t('Actions'),
      sortable: false,
      filterable: false,
      hideable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Button
              sx={{
                marginRight: '5px',
                marginLeft: '5px',
                textTransform: 'none',
              }}
              variant="contained"
              onClick={(e) => {
                e.stopPropagation();
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                setTargetSchoolYear(params.row.schoolYear as ISchoolYear);
                setSchoolYearDialogMode('EDIT');
                setDateValidator((_cur: DateValidator) => {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  return params.row.dateValidator as DateValidator;
                });
                setSchoolYearDialogState(true);
              }}
            >
              {t('Edit School Year')}
            </Button>
            <Button
              sx={{
                marginRight: '5px',
                marginLeft: '5px',
                textTransform: 'none',
              }}
              variant="contained"
              disabled={
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                (params.row.schoolYearType as SchoolYearType) ===
                  'CURRENT_SCHOOL_YEAR' ||
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                (params.row.classroomCount as number) !== 0
              }
              onClick={(e) => {
                e.stopPropagation();
                setSchoolYearDialogMode('DELETE');
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                setTargetSchoolYear(params.row.schoolYear as ISchoolYear);
                setSchoolYearDialogState(true);
              }}
            >
              {t('Delete School Year')}
            </Button>
            <Button
              sx={{
                marginRight: '5px',
                marginLeft: '5px',
                textTransform: 'none',
              }}
              variant="contained"
              disabled={
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                (params.row.schoolYearType as SchoolYearType) ===
                'PAST_SCHOOL_YEAR'
              }
              onClick={(e) => {
                e.stopPropagation();
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                const schoolYearId = params.row.schoolYear.id as string;
                navigate('/classrooms', {
                  state: { schoolYearId: schoolYearId },
                });
              }}
            >
              {t('Manage Classrooms')}
            </Button>
            <Button
              sx={{
                marginRight: '5px',
                marginLeft: '5px',
                textTransform: 'none',
              }}
              disabled={
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                (params.row.schoolYearType as SchoolYearType) !==
                'NEXT_SCHOOL_YEAR'
              }
              variant="contained"
              onClick={(e) => {
                e.stopPropagation();
                console.log(`Make it current`);
              }}
            >
              {t('Make it current')}
            </Button>
          </>
        );
      },
    },
  ];
  if (rows.length === 0) {
    return null;
  }
  if (isSchoolYearsLoading) {
    return <Loader />;
  }
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box sx={{ verticalAlign: 'middle' }}>
          {t('CurrentSchoolYearSentence', { currentSchoolYearName })}
        </Box>
        <Box sx={{ flexGrow: '3' }}>
          <DataGrid
            sx={{
              [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                {
                  outline: 'none',
                },
              [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                {
                  outline: 'none',
                },
              width: `100%`,
              marginTop: '20px',
            }}
            rows={rows}
            columns={columns}
            hideFooter={true}
            autoHeight={true}
            disableColumnMenu={true}
            disableRowSelectionOnClick={true}
            showCellVerticalBorder={true}
            showColumnVerticalBorder={true}
          />
        </Box>
        {!hasNextYear ? (
          <Box
            sx={{
              flexGrow: '1',
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: '200px',
                marginTop: '20px',
              }}
              onClick={(e) => {
                e.stopPropagation();
                setDateValidator((_cur: DateValidator) => {
                  return dateValidatorForNextSchoolYear;
                });
                setSchoolYearDialogMode('CREATE');
                setSchoolYearDialogState(true);
              }}
            >
              {t('Create a School Year')}
            </Button>
          </Box>
        ) : null}
      </Box>
      {/* The SchoolYearDialog below is either editing the current school year
          or creating the next shcool year.
        */}
      <SchoolYearDialog
        mode={schoolYearDialogMode}
        schoolYear={targetSchoolYear}
        handleClose={handleClose}
        onFinish={onFinish}
        open={schoolYearDialogState}
        isCreatingCurrent={false}
        dateValidator={dateValidator}
      />
    </>
  );
}
