import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
  DataGrid,
  gridClasses,
  GridColDef,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { IClassroom, ISchoolYear } from '../../services/firebase/Firebase';
import {
  ClassroomDialog,
  ClassroomDialogMode,
} from '../../dialogs/ClassroomDialog';

interface ClassroomTableProps {
  refresh: () => void;
  targetSchoolYear?: ISchoolYear;
  classrooms: IClassroom[];
}

export function ClassroomTable(props: ClassroomTableProps) {
  const { classrooms, refresh, targetSchoolYear } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [targetClassroom, setTargetClassroom] = useState<
    IClassroom | undefined
  >();
  const [classroomDialogState, setClassroomDialogState] = useState(false);
  const [classroomDialogMode, setClassroomDialogMode] =
    useState<ClassroomDialogMode>('CREATE');
  const handleClose = useCallback(() => {
    setTargetClassroom(undefined);
    setClassroomDialogState(false);
  }, []);
  const onFinish = useCallback(() => {
    setTargetClassroom(undefined);
    setClassroomDialogState(false);
    setTimeout(() => {
      refresh();
    }, 1000);
  }, []);
  const rows: GridValidRowModel[] = [];
  for (const classroom of classrooms) {
    rows.push({
      id: classroom.id,
      name: classroom.name,
      schoolYear: classroom.schoolYear.name,
      numberOfStudents: classroom.emails.length,
      classroom: classroom,
    });
  }
  const columns: GridColDef[] = [
    {
      field: 'schoolYear',
      headerName: t('School Year'),
      flex: 1,
      sortable: false,
      filterable: false,
      hideable: false,
      type: 'string',
    },
    {
      field: 'name',
      headerName: t('Name of the classroom'),
      flex: 1,
      sortable: false,
      filterable: false,
      hideable: false,
      type: 'string',
    },
    {
      field: 'numberOfStudents',
      headerName: t('Number of students'),
      flex: 1,
      sortable: false,
      filterable: false,
      hideable: false,
      type: 'number',
    },
    {
      field: 'actions',
      headerName: t('Actions'),
      sortable: false,
      filterable: false,
      hideable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Button
              sx={{
                marginRight: '5px',
                marginLeft: '5px',
                textTransform: 'none',
              }}
              variant="contained"
              onClick={(e) => {
                e.stopPropagation();
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                setTargetClassroom(params.row.classroom as IClassroom);
                setClassroomDialogMode('EDIT');
                setClassroomDialogState(true);
              }}
            >
              {t('Edit Classroom')}
            </Button>
            <Button
              sx={{
                marginRight: '5px',
                marginLeft: '5px',
                textTransform: 'none',
              }}
              variant="contained"
              disabled={
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                (params.row.classroom as IClassroom).emails.length !== 0
              }
              onClick={(e) => {
                e.stopPropagation();
                setClassroomDialogMode('DELETE');
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                setTargetClassroom(params.row.classroom as IClassroom);
                setClassroomDialogState(true);
              }}
            >
              {t('Delete Classroom')}
            </Button>
            <Button
              sx={{
                marginRight: '5px',
                marginLeft: '5px',
                textTransform: 'none',
              }}
              variant="contained"
              onClick={(e) => {
                e.stopPropagation();
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                const classroom = params.row.classroom as IClassroom;
                navigate('/students', {
                  state: {
                    classroomId: classroom.id,
                    schoolYearId: classroom.schoolYear.id,
                  },
                });
              }}
            >
              {t('View Students')}
            </Button>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Box
        sx={{
          flexGrow: '3',
        }}
      >
        <DataGrid
          sx={{
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
              {
                outline: 'none',
              },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
              {
                outline: 'none',
              },
            width: `100%`,
            marginTop: '20px',
          }}
          rows={rows}
          columns={columns}
          hideFooter={true}
          autoHeight={true}
          disableColumnMenu={true}
          disableRowSelectionOnClick={true}
          showCellVerticalBorder={true}
          showColumnVerticalBorder={true}
        />
      </Box>
      <Box sx={{ flexGrow: '1', paddingTop: '20px', paddingBottom: '20px' }}>
        <Button
          variant="contained"
          sx={{
            width: '200px',
          }}
          disabled={!targetSchoolYear}
          onClick={(e) => {
            e.stopPropagation();
            setClassroomDialogMode('CREATE');
            setTargetClassroom(undefined);
            setClassroomDialogState(true);
          }}
        >
          {t('Create a classroom')}
        </Button>
      </Box>
      <ClassroomDialog
        mode={classroomDialogMode}
        classroom={targetClassroom}
        schoolYear={targetSchoolYear}
        handleClose={handleClose}
        onFinish={onFinish}
        open={classroomDialogState}
      />
    </>
  );
}
