import { useTranslation } from 'react-i18next';
import { useFirebaseAuth } from '../services/FirebaseAuthContext';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

interface AccountInfoDialogProps {
  handleClose: () => void;
  open: boolean;
}

export function AccountInfoDialog({
  handleClose,
  open,
}: AccountInfoDialogProps) {
  const { t } = useTranslation();
  const { user, educationInstitutionUser } = useFirebaseAuth();
  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>{t('Account Information')}</DialogTitle>
      <DialogContent>
        <main>
          <section>
            {user ? (
              <>
                <p>
                  {t('Username')}: {user.displayName}
                </p>
                <p>
                  {t('Email address')}: {user.email}
                </p>
                {!educationInstitutionUser ||
                educationInstitutionUser.userType !== 'teacher' ? (
                  <>
                    <Typography color="red">
                      {t('The user is not registered as a teacher.')}
                    </Typography>
                  </>
                ) : null}
              </>
            ) : null}
            <hr />
            <Button id="closeButton" variant="contained" onClick={handleClose}>
              {t('Close')}
            </Button>
          </section>
        </main>
      </DialogContent>
    </Dialog>
  );
}
