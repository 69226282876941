import * as React from 'react';
import { User } from 'firebase/auth';
import {
  Firebase,
  IUserData,
  IEducationInstitutionUser,
} from './firebase/Firebase';

import { firebaseConfigurationByImportMetaEnv } from './firebase/FirebaseConfiguration';
import { isError } from '../jasmine-common-lib/utils/FailableResult';
import { useTranslation } from 'react-i18next';

export enum SignInState {
  Unknown = 'Unknown',
  SignedOut = 'SignedOut',
  FetchingData = 'FetchingData',
  SignedIn = 'SignedIn',
}

interface ContextState {
  user: User | null;
  userData: IUserData | null;
  educationInstitutionUser: IEducationInstitutionUser | null;
  firebaseInstance: Firebase;
  refreshEducationInstitutionUser: () => Promise<void>;
  lastSignInError: string;
  signInState: SignInState;
}

interface Props {
  children: React.ReactNode;
}

export const firebaseInstance = new Firebase(
  firebaseConfigurationByImportMetaEnv
);

const FirebaseAuthContext = React.createContext<ContextState | undefined>(
  undefined
);

const isValidUserForSystem = function (
  educationInstitutionUser: IEducationInstitutionUser | undefined
): boolean {
  if (educationInstitutionUser === undefined) {
    return false;
  }
  return (
    educationInstitutionUser.educationInstitutions.filter(
      (i) => i.userType === 'teacher'
    ).length > 0
  );
};

export const FirebaseAuthProvider: React.FC<Props> = ({ children }) => {
  const { t, i18n } = useTranslation();
  const [user, setUser] = React.useState<User | null>(null);
  const [signInState, setSignInState] = React.useState<SignInState>(
    SignInState.Unknown
  );
  const [userData, setUserData] = React.useState<IUserData | null>(null);
  const [educationInstitutionUser, setEducationInstitutionUser] =
    React.useState<IEducationInstitutionUser | null>(null);
  const [lastSignInError, setLastSignInError] = React.useState<string>('');

  const refreshEducationInstitutionUser = async (): Promise<void> => {
    const checkUserBelongsToEducationInstitutionResult =
      await firebaseInstance.checkUserBelongsToEducationInstitution();
    if (isError(checkUserBelongsToEducationInstitutionResult)) {
      throw new Error(
        `Fetching education institution user failed. Error=${checkUserBelongsToEducationInstitutionResult.error.message}`
      );
    }
    setEducationInstitutionUser(
      checkUserBelongsToEducationInstitutionResult.value ?? null
    );
  };

  const value = {
    user: user,
    userData: userData,
    educationInstitutionUser: educationInstitutionUser,
    firebaseInstance: firebaseInstance,
    refreshEducationInstitutionUser: refreshEducationInstitutionUser,
    lastSignInError: lastSignInError,
    signInState: signInState,
  };

  React.useEffect(() => {
    const unsubscribe = firebaseInstance
      .getAuth()
      .onAuthStateChanged((user) => {
        if (user) {
          setLastSignInError('');
          setSignInState(SignInState.FetchingData);
          const fetchUserData = async () => {
            const checkUserBelongsToEducationInstitutionPromise =
              firebaseInstance.checkUserBelongsToEducationInstitution();
            const userDataPromise = firebaseInstance.fetchUserData();
            const [checkUserBelongsToEducationInstitutionResult, userData] =
              await Promise.all([
                checkUserBelongsToEducationInstitutionPromise,
                userDataPromise,
              ]);
            if (isError(checkUserBelongsToEducationInstitutionResult)) {
              console.log(
                `Fetching education institution user failed. Error=${checkUserBelongsToEducationInstitutionResult.error.message}`
              );
              setSignInState(SignInState.SignedOut);
              await firebaseInstance.signOut();
              setLastSignInError(t('Failed to fetch education institutions'));
              return;
            }
            if (
              !isValidUserForSystem(
                checkUserBelongsToEducationInstitutionResult.value
              )
            ) {
              setSignInState(SignInState.SignedOut);
              await firebaseInstance.signOut();
              setLastSignInError(t('The user is not registered as a teacher.'));
              return;
            }
            // change language
            const lang =
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              checkUserBelongsToEducationInstitutionResult.value!
                .educationInstitution.supportedLocale;
            const language =
              lang === 'ja_kanji' || lang === 'ja_kanji_elementary'
                ? 'ja'
                : 'en';
            void i18n.changeLanguage(language);
            setEducationInstitutionUser(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              checkUserBelongsToEducationInstitutionResult.value!
            );
            setSignInState(SignInState.SignedIn);
            setUserData(userData);
            setUser(user);
          };
          void fetchUserData();
        } else {
          setUser(user);
          setSignInState(SignInState.SignedOut);
          setUserData(null);
          setEducationInstitutionUser(null);
          setLastSignInError('');
        }
      });
    return unsubscribe;
  }, []);

  return (
    <FirebaseAuthContext.Provider value={value}>
      {children}
    </FirebaseAuthContext.Provider>
  );
};

export function useFirebaseAuth() {
  const context = React.useContext(FirebaseAuthContext);
  if (context === undefined) {
    throw new Error(
      'useFirebaseAuth must be used within a FirebaseAuthProvider'
    );
  }
  return context;
}
