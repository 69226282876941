import { ISchoolYear } from '../services/firebase/Firebase';
import {
  errorResultOf,
  FailableResult,
  successResult,
} from '../jasmine-common-lib/utils/FailableResult';

type DateValidatorError =
  | 'No date validater is configured'
  | 'End Date must be a future date'
  | 'End Date must be earlier then the End Date of the next school year'
  | 'End Date must be later then the End Date of the previous school year';

export const END_DATE_ERROR_NO_VALIDATOR = 'No date validater is configured';
export const END_DATE_ERROR_MUST_BE_FUTURE = 'End Date must be a future date';
export const END_DATE_ERROR_MUST_BE_EARLIER_THAN_NEXT_SCHOOL_YEAR =
  'End Date must be earlier then the End Date of the next school year';
export const END_DATE_ERROR_MUST_BE_LATER_THAN_PREVIOUS_SCHOOL_YEAR =
  'End Date must be later then the End Date of the previous school year';

export type DateValidator = (d: Date) => FailableResult<DateValidatorError>;

export const useDateValidators = function (
  schoolYears: ISchoolYear[],
  currentSchoolYearId: string
): DateValidator[] {
  const ret: DateValidator[] = [];
  for (let i = 0; i < schoolYears.length; ++i) {
    const nextSchoolYear =
      i === schoolYears.length - 1 ? undefined : schoolYears[i + 1];
    const previousSchoolYear = i === 0 ? undefined : schoolYears[i - 1];
    ret.push((d: Date) => {
      const today = new Date();
      if (schoolYears[i].id === currentSchoolYearId && d <= today) {
        return errorResultOf('End Date must be a future date');
      }
      if (nextSchoolYear && d >= nextSchoolYear.endDate) {
        return errorResultOf(
          'End Date must be earlier then the End Date of the next school year'
        );
      }
      if (previousSchoolYear && d <= previousSchoolYear.endDate) {
        return errorResultOf(
          'End Date must be later then the End Date of the previous school year'
        );
      }
      return successResult();
    });
  }
  return ret;
};
