import { useCallback, useRef, useState } from 'react';
import { useFormik } from 'formik';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { jaJP } from '@mui/x-date-pickers/locales';
import { enUS } from '@mui/x-date-pickers/locales';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import 'dayjs/locale/en';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { useFirebaseAuth } from '../services/FirebaseAuthContext';
import {
  ISchoolYear,
  SchoolYearFormValues,
} from '../services/firebase/Firebase';
import { useLegacyClassroomSchoolYears } from '../services/FirestoreHooks';
import { NotificationSnackbar } from '../pages/contents/NotificationSnackbar';
import { isError, isSuccess } from '../jasmine-common-lib/utils/FailableResult';
import * as yup from 'yup';
import { DateValidator } from '../utils/SchoolYearUtils';

export type SchoolYearDialogMode = 'CREATE' | 'EDIT' | 'DELETE';

interface SchoolYearDialogProps {
  mode: SchoolYearDialogMode;
  schoolYear?: ISchoolYear;
  handleClose: () => void;
  onFinish: () => void;
  open: boolean;
  isCreatingCurrent: boolean;
  dateValidator: DateValidator;
}

export function SchoolYearDialog(props: SchoolYearDialogProps) {
  const {
    mode,
    schoolYear,
    handleClose,
    onFinish,
    open,
    isCreatingCurrent,
    dateValidator,
  } = props;
  console.log(
    `Rendering SchoolYearDialog, schoolYear: ${JSON.stringify(schoolYear)}`
  );
  const { t } = useTranslation();
  const { educationInstitutionUser, firebaseInstance } = useFirebaseAuth();
  const legacyClassroomSchoolYears = useLegacyClassroomSchoolYears(
    educationInstitutionUser?.educationInstitution.id
  );
  const isJapanese =
    educationInstitutionUser?.educationInstitution.supportedLocale ===
      'ja_kanji' ||
    educationInstitutionUser?.educationInstitution.supportedLocale ===
      'ja_kanji_elementary';

  const localeText = isJapanese
    ? jaJP.components.MuiLocalizationProvider.defaultProps.localeText
    : enUS.components.MuiLocalizationProvider.defaultProps.localeText;
  const [snackbarMessage, setSnackbarMessage] = useState<string | undefined>();
  const defaultValues = useRef<SchoolYearFormValues>({
    name: '',
    endDate: new Date(),
    migrateExistingClassrooms: false,
    legacyClassroomSchoolYear: 0,
    reuseExistingClassrooms: false,
    isCreatingCurrent: isCreatingCurrent,
  });
  const deleteSchoolYear = useCallback(async () => {
    try {
      setSnackbarMessage(t('Deleting the School Year'));
      const result = await firebaseInstance.deleteSchoolYear(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        educationInstitutionUser!.educationInstitution.id,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        schoolYear!.id
      );
      if (isSuccess(result)) {
        setSnackbarMessage(t('The School Year has been successfully deleted'));
        onFinish();
      } else {
        setSnackbarMessage(t('Failed to delete the School Year'));
      }
    } catch (e) {
      console.error(e);
      setSnackbarMessage(t('Failed to delete the School Year'));
    }
  }, [schoolYear]);

  const validationSchema = yup.object().shape({
    name: yup.string().required(t('Required')),
    endDate: yup
      .date()
      .required(t('Required'))
      .test({
        name: 'endDate-check',
        test(value, ctx) {
          const result = dateValidator(value);
          if (isError(result)) {
            return ctx.createError({
              message: t(result.error),
            });
          }
          return true;
        },
      }),
    migrateExistingClassrooms: yup.boolean(),
    legacyClassroomSchoolYear: yup
      .number()
      .when('migrateExistingClassrooms', {
        is: true,
        then: (schema) => schema.required().min(1),
      })
      .when('migrateExistingClassrooms', {
        is: false,
        then: (schema) => schema.nullable(),
      }),
    reuseExistingClassrooms: yup.boolean(),
    isCreatingCurrent: yup.boolean(),
  });
  const formik = useFormik({
    initialValues: schoolYear
      ? {
          name: schoolYear.name,
          endDate: schoolYear.endDate,
          migrateExistingClassrooms: false,
          legacyClassroomSchoolYear: 0,
          reuseExistingClassrooms: false,
          isCreatingCurrent: isCreatingCurrent,
        }
      : defaultValues.current,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSnackbarMessage(
        mode === 'EDIT'
          ? t('Updating the School Year')
          : t('Creating a new School Year')
      );
      try {
        const result = schoolYear
          ? await firebaseInstance.updateSchoolYear(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              educationInstitutionUser!.educationInstitution.id,
              schoolYear.id,
              values
            )
          : await firebaseInstance.createSchoolYear(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              educationInstitutionUser!.educationInstitution.id,
              values
            );
        if (isSuccess(result)) {
          setSnackbarMessage(
            mode === 'EDIT'
              ? t('The School Year has been successfully updated')
              : t('A School Year has been successfully created')
          );
          onFinish();
        } else {
          setSnackbarMessage(
            mode === 'EDIT'
              ? t('Failed to update the School Year')
              : t('Failed to create a new School Year')
          );
        }
      } catch (e) {
        console.error(e);
        setSnackbarMessage(
          mode === 'EDIT'
            ? t('Failed to update the School Year')
            : t('Failed to create a new School Year')
        );
      } finally {
        setSubmitting(false);
      }
      setSubmitting(false);
    },
  });
  if (mode === 'DELETE') {
    return (
      <>
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="sm"
        >
          <Box sx={{ margin: '20px' }}>
            {t('Delete School Year Confirmation Message', {
              name: schoolYear?.name,
            })}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              variant="contained"
              sx={{ margin: '16px 8px', textTransform: 'none' }}
              onClick={(e) => {
                e.stopPropagation();
                void deleteSchoolYear();
              }}
            >
              {t('Delete the School Year')}
            </Button>
            <Button
              variant="contained"
              sx={{ margin: '16px 8px', textTransform: 'none' }}
              onClick={() => {
                handleClose();
              }}
            >
              {t('Close')}
            </Button>
          </Box>
        </Dialog>
        <NotificationSnackbar message={snackbarMessage} />
      </>
    );
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        {mode === 'EDIT' ? (
          <DialogTitle>{t('Editing a School Year')}</DialogTitle>
        ) : (
          <DialogTitle>{t('Creating a School Year')}</DialogTitle>
        )}
        <form onSubmit={formik.handleSubmit}>
          <FormControl sx={{ display: 'block', margin: '16px 8px' }}>
            <Box component="section" sx={{ display: 'flex' }}>
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  flexDirection: 'columns',
                  alignItems: 'center',
                }}
              >
                <Box component="div" sx={{ margin: '8px', width: '200px' }}>
                  {t('Name of the School Year')}
                </Box>
                <TextField
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.name)}
                  helperText={
                    formik.errors.name ? <>{formik.errors.name}</> : null
                  }
                ></TextField>
              </Box>
            </Box>
          </FormControl>
          <FormControl sx={{ display: 'block', margin: '16px 8px' }}>
            <Box component="section" sx={{ display: 'flex' }}>
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  flexDirection: 'columns',
                  alignItems: 'center',
                }}
              >
                <Box component="div" sx={{ margin: '8px', width: '200px' }}>
                  {t('End Date')}
                </Box>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  localeText={localeText}
                  adapterLocale={isJapanese ? 'ja' : 'en'}
                >
                  <DatePicker
                    defaultValue={dayjs(formik.values.endDate)}
                    value={dayjs(formik.values.endDate)}
                    onChange={(value) => {
                      void formik.setFieldValue(
                        'endDate',
                        value ? value.toDate() : undefined,
                        true
                      );
                    }}
                    slotProps={{
                      textField: {
                        variant: 'outlined',
                        error: Boolean(formik.errors.endDate),
                        helperText: formik.errors.endDate ? (
                          <>{formik.errors.endDate}</>
                        ) : null,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
          </FormControl>
          {schoolYear === undefined && !isCreatingCurrent ? (
            <FormControl>
              <Box component="section" sx={{ display: 'flex' }}>
                <Box
                  component="div"
                  sx={{
                    display: 'flex',
                    flexDirection: 'columns',
                    alignItems: 'center',
                  }}
                >
                  <Box component="div" sx={{ margin: '16px', width: 'auto' }}>
                    {t(
                      'Create classes with the same name as the classes in the current School Year'
                    )}
                  </Box>
                  <Checkbox
                    defaultChecked={formik.values.reuseExistingClassrooms}
                    onChange={(value) => {
                      void formik.setFieldValue(
                        'reuseExistingClassrooms',
                        value.target.checked,
                        true
                      );
                    }}
                  />
                </Box>
              </Box>
            </FormControl>
          ) : null}
          {isCreatingCurrent && legacyClassroomSchoolYears.length > 0 ? (
            <FormControl>
              <Box component="section" sx={{ display: 'flex' }}>
                <Box
                  component="div"
                  sx={{
                    display: 'flex',
                    flexDirection: 'columns',
                    alignItems: 'center',
                  }}
                >
                  <Box component="div" sx={{ margin: '16px', width: 'auto' }}>
                    {t('Migrate legacy classrooms to this School Year')}
                  </Box>
                  <Checkbox
                    defaultChecked={formik.values.migrateExistingClassrooms}
                    onChange={(value) => {
                      void formik.setFieldValue(
                        'migrateExistingClassrooms',
                        value.target.checked,
                        true
                      );
                    }}
                  />
                </Box>
              </Box>
              <Box component="section" sx={{ display: 'flex' }}>
                <Box
                  component="div"
                  sx={{
                    display: 'flex',
                    flexDirection: 'columns',
                    alignItems: 'center',
                  }}
                >
                  <Box component="div" sx={{ margin: '16px', width: 'auto' }}>
                    {t('Choose the School Year to migrate the Classrooms')}
                  </Box>
                  <Select
                    name="legacyClassroomSchoolYear"
                    labelId="legacyClassroomSchoolYear"
                    id="legacyClassroomSchoolYearInput"
                    value={formik.values.legacyClassroomSchoolYear}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.errors.legacyClassroomSchoolYear)}
                    displayEmpty
                  >
                    <MenuItem value="0" disabled>
                      {t('Please Select')}
                    </MenuItem>
                    {legacyClassroomSchoolYears.map((schoolYear) => {
                      return (
                        <MenuItem value={schoolYear} key={schoolYear}>
                          {schoolYear}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>
              </Box>
            </FormControl>
          ) : null}
          <Divider sx={{ margin: '8px 8px' }} />
          {mode === 'EDIT' ? (
            <Button
              variant="contained"
              type="submit"
              sx={{ margin: '16px 8px', textTransform: 'none' }}
              disabled={!formik.dirty || !formik.isValid}
            >
              {t('Save the changes')}
            </Button>
          ) : (
            <Button
              variant="contained"
              type="submit"
              sx={{ margin: '16px 8px', textTransform: 'none' }}
              disabled={!formik.dirty || !formik.isValid}
            >
              {t('Create a School Year')}
            </Button>
          )}
          <Button
            variant="contained"
            sx={{ margin: '16px 8px', textTransform: 'none' }}
            onClick={() => {
              handleClose();
            }}
          >
            {t('Close')}
          </Button>
        </form>
      </Dialog>
      <NotificationSnackbar message={snackbarMessage} />
    </>
  );
}
